<template>
  <main class="login-body">
    <CreatePassword />
  </main>
</template>

<script>
import CreatePassword from '@/components/registration/CreatePassword.vue';
import intercom from '../utils/intercom';

export default {
  name: 'Registration',
  components: {
    CreatePassword
  },
  created () {
    intercom();

    const token = this.$route.query.token;
    if (token) {
      this.$router.push({ query: null });
      localStorage.setItem('tokenGamayun', token);
      this.$store.commit('setSignUpToken', token);

      // this.$store.commit('setToken');
      // this.$store.commit('setAuthHeader');
      // this.$store.dispatch('loadProducts');
    }
  }
};
</script>
