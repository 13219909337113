<template>
  <div class="px-8" v-if="!mobileStub">
    <template v-if="activeForm">
      <h1 class="typography-1 color-black mb-4">Create password</h1>
      <div class="typography-base">
        The password must contain:
        <ul class="list-disc">
          <li>Uppercase and lowercase letters (Aa, Bb, Cc)</li>
          <li>Numbers (0...9)</li>
        </ul>
      </div>
      <div class="mb-12">
        <div class="mb-8">
          <label for="password" class="typography-caption color-2 mb-8 let-space-08">Password</label>
          <div class="input-block">
            <input type="password" id="password" :placeholder="'At least ' + v$.password.minLength.$params.min + ' characters'" class="input bg-form" ref="signin-password" :class="errors.password && v$.password.$error ? 'error-border' : ''" v-model.trim="v$.password.$model">
          </div>
        </div>
        <div>
          <label for="confirm" class="typography-caption color-2 mb-8 let-space-08">Confirm password</label>
          <div class="input-block">
            <input type="password" id="confirm" placeholder="Enter the password again" class="input bg-form" ref="signin-password-2" :class="errors.password && passwordError ? 'error-border' : ''" v-model.trim="passwordConfirm">
          </div>
        </div>
      </div>
      <div class="error" v-if="errors.password && (v$.password.$error || passwordError || serverError)">
        <Icon name="warning" class="mr-13"/>
        <div class="d-flex flex-column align-start">
          <span v-show="v$.password.minLength.$invalid">Password must have at least {{v$.password.minLength.$params.min}} letters.</span>
          <span v-show="v$.password.maxLength.$invalid">Password must have no more than {{v$.password.maxLength.$params.max}} letters.</span>
          <span v-show="passwordError">Passwords must match</span>
          <span v-show="serverError">{{serverError}}</span>
        </div>
      </div>
      <div class="container px-0 mt-32">
        <div class="row mx--8">
          <div class="col-12 col-md-6 px-8 mb-16">
            <button
              id="create-password"
              class="primary-btn typography-base mr-16 w-sm-100"
              :disabled="!password || passwordError || loading"
              @click="passwordHandle"
            >
              Create password
            </button>
          </div>
          <div class="col-12 col-md-6 px-8">
            <span class="d-block typography-6 terms-color">By clicking this button I agree to the <router-link to="/terms" target="_blank" class="color-theme">policy & terms</router-link></span>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="text-center px-8">
      <img src="@/assets/icons/ico_done.png" alt="" class="mb-32">
      <h1 class="typography-1 color-1 text-center mb-8">Password reset successfully</h1>
      <div class="typography-3 color-2 text-center mb-32">Try to log in again</div>
      <button id="back-to-login" class="primary-btn typography-base mx-auto" @click="backToLogin">Sign in</button>
    </div>
  </div>
  <div class="px-8 text-center" v-else>
    <img src="@/assets/icons/finish-mobile-reg.png" alt="" class="mb-48">
    <h2 class="typography-3 color-black mb-16">Registration complete</h2>
    <span class="typography-11">Log in from your desktop to start using the product</span>
  </div>
</template>

<script>
import Icon from '../app/Icon.vue';
import { minLength, maxLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { mapGetters } from 'vuex';
import axios from 'axios';
import amplitude from 'amplitude-js';

export default {
  name: 'CreatePassword',
  components: {
    Icon
  },
  props: ['sendCode', 'email', 'phone'],
  emits: ['closeForm'],
  data () {
    return {
      password: '',
      passwordConfirm: '',
      codeSendTimeout: '',
      serverError: '',
      v$: useVuelidate(),
      loading: false,
      mobileStub: false,
      activeForm: true,
      errors: {
        password: false,
        passwordsNotMatch: false
      }
    };
  },
  validations () {
    return {
      password: {
        minLength: minLength(8),
        maxLength: maxLength(64)
      }
    };
  },
  computed: {
    ...mapGetters(['signUpToken', 'codeForChangePassword', 'phoneToChangePassword']),

    passwordError () {
      return this.password !== this.passwordConfirm;
    }
  },
  methods: {
    checkErrorsForShow () {
      this.errors.password = false;
      this.errors.passwordsNotMatch = false;
      this.errors.password = this.v$.password.$error;
      if (this.errors.password || (this.password !== this.passwordConfirm)) {
        return 'error';
      }
    },
    async createPassword () {
      const checkErrors = this.checkErrorsForShow();
      if (checkErrors === 'error') return;

      const event = 'reg_pass';
      amplitude.getInstance().logEvent(event);

      try {
        this.loading = true;
        this.serverError = '';
        const body = {
          password: this.password
        };
        const finish = await axios.put(`/api/v3/signup/finish/${this.signUpToken}`, body);

        if (document.body.clientWidth <= 764) {
          this.mobileStub = true;
        } else {
          const token = {
            access: finish.data.access,
            refresh: finish.data.refresh
          };
          localStorage.setItem('tokenGamayun', JSON.stringify(token));
          this.$store.commit('setToken');
          this.$store.commit('setAuthHeader');
          await this.$store.dispatch('loadProducts');
          try {
            await this.$store.dispatch('checkConfirmInformation');
          } catch (e) {
            console.error(e);
          }
          await this.$store.dispatch('checkAccount');
          await this.$store.dispatch('setAddNewCase');
          window.roistat.event.send('gamayunreg');
        }
        // Deleting temporary registration objects with data
        this.$store.commit('setMainInfoFromReg', null);
        this.$store.commit('setCompanyInfoFromReg', null);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.errors.password = true;
        if (error.response?.data?.detail) {
          this.serverError = error.response.data.detail;
        } else {
          this.serverError = 'Something went wrong, try again later';
        }
      }
    },
    async resetPassword () {
      const checkErrors = this.checkErrorsForShow();
      if (checkErrors === 'error') return;

      try {
        this.serverError = '';
        const body = {
          code: this.sendCode,
          password: this.password
        };
        if (this.phone) {
          body.phone = this.phone;
        } else {
          body.email = this.email;
        }
        await axios.post('/api/v2/password/reset', body);
        this.password = '';
        this.$emit('closeForm');
      } catch (error) {
        this.errors.password = true;
        this.serverError = error.response.data[0];
      }
    },

    async resetPasswordNew () {
      const checkErrors = this.checkErrorsForShow();
      if (checkErrors === 'error') return;

      try {
        this.serverError = '';
        const body = {
          password: this.password
        };
        if (this.phoneToChangePassword) {
          body.phone = this.phoneToChangePassword;
          body.code = this.codeForChangePassword;
        } else {
          body.code = this.codeForChangePassword;
        }
        await axios.post('/api/v2/password/reset', body);
        this.password = '';
        this.activeForm = false;
      } catch (error) {
        this.errors.password = true;
        if (error.response?.data?.detail) {
          this.serverError = error.response.data.detail;
        } else {
          this.serverError = 'Something went wrong, try again later';
        }
      }
    },
    passwordHandle () {
      if (this.signUpToken) {
        this.createPassword();
      } else {
        this.resetPasswordNew();
      }
    },
    backToLogin () {
      this.$store.commit('setCodeForChangePassword', null);
      this.$store.commit('setPhoneToChangePassword', null);
      this.$router.push('/login');
    }
  }
};
</script>
